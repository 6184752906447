import React, { useState, useEffect }         from 'react';
import AuthService                    from 'services/auth.service';
import LuckyDrawService               from 'services/lucky-draw.service';
import BookdocIcon    from 'assets/images/bookdoc-icon.svg';
import Prizes         from './Prizes';

export default function Landing() {
  const [tnbDraw, setTnbDraw] = useState({});
  useEffect(() => {
    LuckyDrawService.loginInfo().then((data) => {
      setTnbDraw(data.latest_lucky_draw);
    }, (error) => {
      console.log(error);

      if (error.response.status === 401) {
        AuthService.logout();
        window.location.replace('/eb/login');
      }
    });
  }, []);

  return (
    <div>
      <div className="corporate-icons position-absolute" style={{ top: '2em', left: '2em', height: '7vh' }}>
        {(tnbDraw && typeof tnbDraw.header_logo !== 'undefined') && 
          <img src={ tnbDraw.header_logo.url }  alt="oth-icon"  className="me-8 h-100" />
        }
        <img src={ BookdocIcon }  alt="bookdoc-icon"  className="h-90" />
      </div>

      <div className="text-center">
        <h2 className="font-quantico text-primary mb-8 main-title">{tnbDraw.name}</h2>
      </div>
      <Prizes tnbDraw={tnbDraw} />
    </div>
  );
}